




















































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vue, Component } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import firebase from "@/plugins/firebase";
import "@/types";

import Sha1 from "crypto-js/sha1";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

@Component({
  components: {
    Breadcrumbs
  },
  metaInfo(this: MyCart) {
    return {
      title: this.pageTitle
    };
  }
})
export default class MyCart extends Vue {
  pageTitle = "Sepetim";

  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "home" }
    },
    {
      text: "Sepetim"
    }
  ];

  submitDisabled = false;

  transferDialog = false;

  // vPOS Test Form
  // paymentForm = {
  //   billType: "bireysel",
  //   name: `${this.user.firstName} ${this.user.lastName}`,
  //   corpName: "",
  //   tckn: "25447861052",
  //   taxNumber: "",
  //   email: this.user.email,
  //   phone: "0533 305 97 76",
  //   city: "Eskişehir",
  //   postCode: "26180",
  //   address: "Lorem Mh. Ipsum Sk. 3/11 Tepebaşı",
  //   paymentType: "creditcard" as "creditcard" | "transfer",
  //   ccNumber: "4155650100416111",
  //   ccHolder: "İlker Kayabaş",
  //   ccDate: "01/25",
  //   ccCvc: "123",
  //   confirm: false
  // };

  paymentForm = {
    billType: "bireysel",
    name: `${this.user.firstName} ${this.user.lastName}`,
    corpName: "",
    tckn: "",
    taxNumber: "",
    email: this.user.email,
    phone: "",
    city: "",
    postCode: "",
    address: "",
    paymentType: "creditcard" as "creditcard" | "transfer",
    ccNumber: "",
    ccHolder: "",
    ccDate: "",
    ccCvc: "",
    confirm: false
  };

  // vPOS action URL
  // vposAction = "https://vpostest.qnbfinansbank.com/Gateway/Default.aspx"; // Test
  vposAction = "https://vpos.qnbfinansbank.com/Gateway/Default.aspx";

  // Kurum Kodu
  mbrId = "5";

  // Üye işyeri numarası
  // merchantID = "085300000009704"; // Test
  merchantID = "026000000031245"; // Production

  // Üye işyeri 3D şifresi
  // merchantPass = "12345678"; // Test
  merchantPass = "54669562";

  // Kullanıcı Kodu
  // userCode = "QNB_API_KULLANICI_3DPAY"; // Test
  userCode = "kahevapi";

  // Kullanıcı Şifre
  // userPass = "UcBN0"; // Test
  userPass = "iL4H9";

  // Güvenlik tipi
  secureType = "3DPay";

  // İşlem Tipi
  txnType = "Auth";

  // Para Birimi
  currency = "949";

  // Başarılı işlem URL
  returnUrl =
    "https://us-central1-kahev-org.cloudfunctions.net/akademi-api/paymentResult";
  // returnUrl = "http://localhost:5001/kahev-org/us-central1/akademi-api/paymentResult";

  get okUrl() {
    return this.returnUrl;
  }

  get failUrl() {
    return this.returnUrl;
  }

  // Taksit sayısı
  installmentCount = 0;

  // Dil
  lang = "TR";

  rnd = "";
  hash = "";
  orderId = "";

  get nameRules() {
    return [(v: string) => !!v || "Lütfen adınızı yazınız"];
  }

  get corpNameRules() {
    return [(v: string) => !!v || "Lütfen kurum adınızı yazınız"];
  }

  get tcknRules() {
    return [
      (v: string) => !!v || "Lütfen TC kimlik numaranızı yazınız",
      (v: string) =>
        /^[1-9]{1}[0-9]{9}[02468]{1}$/.test(v) || "Geçersiz TC kimlik numarası"
    ];
  }

  get taxNumberRules() {
    return [
      (v: string) => !!v || "Lütfen vergi numaranızı yazınız",
      (v: string) => /^[0-9]{10}$/.test(v) || "Geçersiz vergi numarası"
    ];
  }

  get emailRules() {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  get phoneRules() {
    return [
      (v: string) => !!v || "Lütfen telefon numaranızı yazınız",
      (v: string) =>
        // Tr telefon 0532 111 11 11 ya da 05321111111
        /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/.test(v) ||
        // Genel +90|00 ile başlayan 8-13 uzunluğunda boşluk ve - içerebilen
        /^((\+|00)[0-9]{1,3})\s?([0-9\s-]{8,13})$/.test(v) ||
        "Geçersiz numara (Örn: 0532 111 11 11 veya +90 532 111 11 11)"
    ];
  }

  get cityRules() {
    return [(v: string) => !!v || "Lütfen şehir adını yazınız"];
  }

  get addressRules() {
    return [(v: string) => !!v || "Lütfen açık adresinizi yazınız"];
  }

  get ccNumberRules() {
    return [
      (v: string) => !!v || "Lütfen kart numaranızı yazınız",
      (v: string) =>
        /^([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})$/.test(v) ||
        "Geçersiz kart numarası"
    ];
  }

  get ccHolderRules() {
    return [(v: string) => !!v || "Lütfen kart sahibini yazınız"];
  }

  get ccDateRules() {
    return [
      (v: string) => !!v || "Lütfen kartınızın son kullanma tarihini yazınız",
      (v: string) => /^([0-9]{2})\/([0-9]{2})$/.test(v) || "Geçersiz tarih"
    ];
  }

  get ccCvcRules() {
    return [
      (v: string) => !!v || "Lütfen CVC kodunu yazınız",
      (v: string) => /^([0-9]{3})$/.test(v) || "Geçersiz CVC"
    ];
  }

  get confirmRules() {
    return [
      this.paymentForm.confirm ||
        "Lütfen mesafeli satış sözleşmesini onaylayınız."
    ];
  }

  get userSignedIn(): boolean {
    return this.$store.getters["auth/userSignedIn"];
  }

  get user(): User {
    return this.$store.getters["auth/user"];
  }

  get cartItemList(): CartItem[] {
    return this.$store.getters["cart/cartItemList"];
  }

  get cartItemCount() {
    return this.$store.getters["cart/cartItemCount"];
  }

  get cartPrice(): number {
    return this.$store.getters["cart/cartPrice"];
  }

  async openCartItem(item: CartItem) {
    try {
      const courseDoc = await firebase
        .firestore()
        .doc(`/apps/kahev-akademi/courses/${item.id}`)
        .get();

      if (courseDoc.exists) {
        const courseUrlCode = courseDoc.data()?.urlCode;
        this.$router.push({
          name: "course-catalog-detail",
          params: { courseUrlCode: courseUrlCode }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  removeCartItem(item: CartItem) {
    this.$store.dispatch("cart/removeItem", item);
    this.$store.dispatch("cart/saveCart");
  }

  validate() {
    const form: any = this.$refs.form;
    return form.validate();
  }

  createCcMask() {
    const normalizedCcNumber = this.paymentForm.ccNumber.replace(/ /g, "");
    const last4Digit = normalizedCcNumber.substring(
      normalizedCcNumber.length - 4,
      normalizedCcNumber.length
    );

    return "************" + last4Digit;
  }

  async saveOrder() {
    // Sipariş oluştur
    const newOrder = await firebase
      .firestore()
      .collection("/apps/kahev-akademi/orders")
      .add({
        uid: this.user.id,
        billType: this.paymentForm.billType,
        name: this.paymentForm.name,
        tckn: this.paymentForm.tckn,
        email: this.paymentForm.email,
        phone: this.paymentForm.phone,
        corpName: this.paymentForm.corpName,
        taxNumber: this.paymentForm.taxNumber,
        city: this.paymentForm.city,
        postCode: this.paymentForm.postCode,
        address: this.paymentForm.address,
        paymentType: this.paymentForm.paymentType,
        ccNumber:
          this.paymentForm.paymentType == "creditcard"
            ? this.createCcMask()
            : "",
        ccHolder:
          this.paymentForm.paymentType == "creditcard"
            ? this.paymentForm.ccHolder
            : "",
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        items: this.cartItemList,
        amount: this.cartPrice,
        installmentCount: this.installmentCount,
        status: null, // null | success | error
        statusMessage:
          this.paymentForm.paymentType == "transfer"
            ? "Para transferi bekleniyor..."
            : "",
        paymentDetails: null
      });

    return newOrder.id;
  }

  microtime() {
    let s,
      // eslint-disable-next-line prefer-const
      now = (Date.now ? Date.now() : new Date().getTime()) / 1000;

    // Dirty trick to only get the integer part
    // eslint-disable-next-line prefer-const
    s = now | 0;

    return Math.round((now - s) * 1000) / 1000 + " " + s;
  }

  calcHash(
    mbrId: string,
    orderId: string,
    amount: string,
    okUrl: string,
    failUrl: string,
    txnType: string,
    installmentCount: string,
    rnd: string,
    merchantPass: string
  ) {
    const str =
      mbrId +
      orderId +
      amount +
      okUrl +
      failUrl +
      txnType +
      installmentCount +
      rnd +
      merchantPass;

    const hash = Sha1(Utf8.parse(str));
    return hash.toString(Base64);
  }

  paymentDialog = false;

  async takePayment() {
    this.submitDisabled = true;
    this.$notify({
      text: "Ödeme alınıyor. Lütfen bekleyin..."
    });

    // Siparişi kaydet
    this.orderId = await this.saveOrder();
    // this.orderId = "1pYgPmUtF9Wkt7ibnP4j";

    // firebase.functions().useEmulator("localhost", 5001);

    // Ödeme al
    //**********************
    this.rnd = this.microtime();
    this.hash = this.calcHash(
      this.mbrId,
      this.orderId,
      this.cartPrice.toString(),
      this.okUrl,
      this.failUrl,
      this.txnType,
      this.installmentCount.toString(),
      this.rnd,
      this.merchantPass
    );

    const formPayment: any = this.$refs.formPayment;
    this.paymentDialog = true;

    // rnd, hash ve orderId güncellenmiyor
    // Biraz gecikme ile formu gönder.
    setTimeout(() => {
      formPayment.submit();
    }, 500);

    // Mesajı temizle
    this.submitDisabled = false;
    this.$notify({
      clean: true
    });
  }

  async takeTransfer() {
    this.submitDisabled = true;
    this.$notify({
      text: "Siparişiniz kaydediliyor. Lütfen bekleyin..."
    });

    // Siparişi kaydet
    this.orderId = await this.saveOrder();

    // Sepeti sil
    this.$store.dispatch("cart/deleteCart");
    this.$store.dispatch("cart/clearCart");

    // Dialog göster
    this.transferDialog = true;

    // Mesajı temizle
    this.submitDisabled = false;
    this.$notify({
      clean: true
    });
  }

  async submit() {
    // Formu kontrol et
    if (!this.validate()) return;

    if (this.paymentForm.paymentType == "transfer") {
      this.takeTransfer();
    } else {
      this.takePayment();
    }
  }
}
